/*
!!IMPORTANT:
Swiper React components will likely be removed in future versions. It is recommended to migrate to Swiper Element instead.

  https://www.freecodecamp.org/news/how-to-set-up-swiper-element-in-a-react-application/
  https://swiperjs.com/blog/using-swiper-element-in-react#custom-wrapper-component

  Types configuration: https://github.com/nolimits4web/swiper/issues/6466

  !!IMPORTANT ACTIONS PROPS:
  Make sure pass all actions of swiper into "on" object, don't pass actions like "onSlideChange"
*/

import {
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { register } from "swiper/element";
import { SwiperProps } from "swiper/react";
import "swiper/css";

import { SwiperRef } from "~/types/swiper";

export interface ForwardedSwiper {
  core: SwiperRef | null;
}

function Swiper(
  props: PropsWithChildren<SwiperProps>,
  ref: ForwardedRef<ForwardedSwiper>
) {
  const swiperRef = useRef<SwiperRef>(null);

  const { children, ...rest } = props;

  useEffect(() => {
    // Register Swiper web component
    register();
    // pass component props to parameters
    const params = {
      ...rest,
    };

    // Assign it to swiper element
    if (swiperRef.current) {
      Object.assign(swiperRef.current, params);

      // initialize swiper
      swiperRef.current.initialize();
    }
  }, [swiperRef.current]);

  useImperativeHandle(
    ref,
    () => ({
      core: swiperRef.current,
    }),
    [swiperRef.current]
  );

  return (
    <swiper-container init="false" ref={swiperRef}>
      {children}
    </swiper-container>
  );
}

export default forwardRef(Swiper);
